import React, {useEffect, useState, useContext, useRef} from 'react';

import customerStore from '../store/customerStore';
import PersonalInfo from "../components/personalInfo/personalInfo.component";
import Steps from "../components/shared/steps.component";
import Layout from '../components/shared/layout'
import SuccessIconG from '../assets/images/Success-Icon_grey.png'
import {graphql} from "gatsby";
import {isMobile as checkMobile, isTablet as checkTablet, ROUTES} from "../app.constant"
import planStore from '../store/planStore';
import deviceStore from '../store/deviceStore';
import { Trans, Translation, useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import { useProductDescByLang } from '../components/shared/handleDesc.component';

export default function() {
    const [isMobile, setMobile] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const { navigate } = useI18next()
    const { language } = useContext(I18nextContext)
    const formRef = useRef();

    // intializeCustomerStore = () => {
    //     customerStore.customerFirstName = '';
    //     customerStore.customerLastName = '';
    //     customerStore.customerEmail = '';
    //     customerStore.customerCountryCode = '';
    //     customerStore.customerPhoneNo = '';
    //     customerStore.customerDateOfBirth = '';
    //     customerStore.customerMailingAddress = '';
    //     customerStore.customerPostcode = '';
    //     customerStore.customerCity = '';
    //     customerStore.userAgeConsent = '';
    //     customerStore.userConsent = '';
    //     customerStore.isValidatedForm = false;
    // }

    useEffect(() => {
      document.title = '';
      setMobile(checkMobile())
      setIsTablet(checkTablet())
      if (!deviceStore.deviceImei) {
        navigate(ROUTES.DEVICE_ELIGIBILITY)
      } else {
        // if there is no selection of plan,
        // should route to step 2
        if (!planStore.planId) {
          navigate(ROUTES.PRODUCT)
        }
      }
    })


    return (
      <div className="personal-info-page">
        <Layout header={true} isBackBtn={true} prevUrl={ROUTES.PRODUCT}>
          <div
            className={
              isMobile ? "" : "desktop-grid-container-layout"
            }
          >
            {!isMobile && (
              <div className="ml-20">
                <aside className="asideTmp">
                  <div className="grid-aside">
                    <div>
                      <img src={SuccessIconG} alt="success icon" />
                    </div>
                    <div style={{ color: "#7F7F7F", fontWeight: "500" }}>
                      {" "}
                      <p>
                        <Trans>desktop.sidebar.step1</Trans>
                      </p>{" "}
                    </div>
                    <div>
                      <img src={SuccessIconG} alt="success icon" />
                    </div>
                    <div style={{ color: "#7F7F7F", fontWeight: "500" }}>
                      {" "}
                      <p>
                        <Trans>desktop.sidebar.step2</Trans>
                      </p>{" "}
                    </div>
                    <div></div>
                    <div style={{ color: "#2189FF", fontWeight: "700" }}>
                      {" "}
                      <label>
                        <Trans>desktop.sidebar.step3</Trans>
                      </label>{" "}
                    </div>
                  </div>
                </aside>
              </div>
            )}
            <div>
              {(isMobile || isTablet) ? (
                <Translation>
                  { (t) => (
                    <Steps
                      class="container-responsive mobile-personal-info-title"
                      step="3"
                      pageTitle={t('payment.summary')}
                    />
                  )}
                </Translation>
              ) : (
                <Translation>
                  { (t) => (
                    <Steps
                      class="container-responsive"
                      step="3"
                      pageTitle={t('step.s3')}
                    />
                  )}
                </Translation>
              )}
              
              <PersonalInfo
                customerStoreProps={customerStore}
                isMobile={isMobile}
                navigate={navigate}
                isTablet={isTablet}
                language={language}
                formRef={formRef}
                productDesc={useProductDescByLang(planStore.productList)}
              />
            </div>
          </div>
        </Layout>
      </div>
    )

}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;