import React, {Component} from 'react';
import deviceStore from '../../store/deviceStore';
import planStore from '../../store/planStore';
import * as constants from '../../app.constant';
import {handleAmount} from "../../app.utils";
import arrow_up from "../../assets/images/arrow-up.png";
import ExpandModalComponent from "./expandModal.component";
import {Trans, withTranslation} from "gatsby-plugin-react-i18next"
import {HandleDesc} from '../shared/handleDesc.component';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import { PrecontractualPopup } from '../product/PrecontractualPopup';
import PhoneInput from 'react-phone-input-2';
import * as moment from 'moment';
import loaderStore from '../../store/loaderStore';
import { getOrderToken } from '../../app.utils';
import { PlanMapping } from '../product/plan-selection.component';
import queryString from 'query-string';
class PersonalInfo extends Component {
    inputRef = {
        fullName: React.createRef(),
        mobileNo: React.createRef(),
        email: React.createRef(),
        dob: React.createRef(),
        mailingAddress: React.createRef(),
        houseNumber: React.createRef(),
        postcode: React.createRef(),
        city: React.createRef()
    }
    constructor(props) {
        super(props);
        this.invalidFields = [];
    }

    state = {
        fullName: '',
        mobileNo: '',
        mobileNoCountry: constants.env.GATSBY_COUNTRY?.toLowerCase(),
        email: '',
        dob: '',
        mailingAddress: '',
        houseNumber: '',
        postcode: '',
        city: '',

        fullNameError: '',
        mobileNoError: '',
        emailError: '',
        dobError: '',
        mailingAddressError: '',
        houseNumberError: '',
        postcodeError: '',
        cityError: '',

        checkbox1: true,
        checkbox2: false,
        isValidField: true,
        visible: false,
        expandClassName1: 'bottom-slide-in-custom-modal-hidden',
        expandClassName2: 'slide-out',
        postcodeLength: "4",

        showPrecontract: false,
        precontractTarget: '',
        token: ''
    }

    componentDidMount() {
        const { customerStoreProps } = this.props;
        if (!deviceStore.deviceModel || !deviceStore.deviceImei) {
          localStorage.clear()
          this.props.navigate(constants.ROUTES.HOME);
        }


        this.fetchCustomerData(customerStoreProps);

        switch (constants.env.GATSBY_COUNTRY) {
            case "BE":
                this.state.postcodeLength = "4"
                break;
            case "NL":
                this.state.postcodeLength = "6"
                break;
            case "FI":
                this.state.postcodeLength = "5"
                break;
            default:
                break;
        }
    }

    fetchCustomerData(customerStoreProps){
        if(deviceStore.isRenewal){
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        "x-api-key": constants.env.GET_PRODUCT_LIST_X_API_KEY,
                        'Content-Type': 'application/json' }
                };
                fetch(`${constants.env.GET_CUSTOMER_API_URL}?idNumber=${customerStoreProps.customerId}&regexSearch=true`, requestOptions).then(res => res.json())
                    .then(response => {
                        if(response.status!== '500' && response.obj.resultList.length>0){
                            const result = response.obj.resultList[0];
                            let address1 = result.addressList[0].address1.split(',');
                            this.setState({
                                fullName: result.fullName,
                                email: result.email,
                                mobileNo: result.mobileNumber,
                                dob: result.dateOfBirth?moment(result.dateOfBirth,'DD/MM/YYYY').format('DD/MM/YYYY'):customerStoreProps.customerDateOfBirth,
                                houseNumber: address1.length>0? address1[0]:'',
                                mailingAddress: address1.length>1? address1[1].replace(/ /g,''):'',
                                postcode: result.addressList[0].postalCode,
                                city: result.addressList[0].city,
                            })
                        }

                    })
            } catch (error) {
                console.log('fetchCustomerData ERROR: ', error.message)
            }
        }
        else{
            this.props.customerStoreProps.customerId = null;
            if (customerStoreProps.isValidatedForm) {
                this.setState({
                    fullName: customerStoreProps.customerFirstName + ` ${customerStoreProps.customerLastName}`,
                })
            }
            else {
                this.setState({
                    fullName: customerStoreProps.customerFirstName,
                })
            }
            let mobileNo = customerStoreProps.customerPhoneNo;
            let customerPhoneNoCountryCode = constants.env.GATSBY_MOBILE_NO_CODE;
            if(mobileNo) {
              mobileNo.replace(customerPhoneNoCountryCode,'');
              console.log('mobileNo', mobileNo)
            }
            this.setState({
                email: customerStoreProps.customerEmail,
                mobileNo: mobileNo,
                dob: customerStoreProps.customerDateOfBirth?moment(customerStoreProps.customerDateOfBirth).format('DD/MM/YYYY'):customerStoreProps.customerDateOfBirth,
                mailingAddress: customerStoreProps.customerMailingAddress,
                houseNumber: customerStoreProps.customerHouseNumber,
                postcode: customerStoreProps.customerPostcode,
                city: customerStoreProps.customerCity,
            })
        }
    }

    handlePhoneInput = (value) => {
      this.state.isValidField = true

      this.setState({
        mobileNo: value
      })
    }

    handleInputRestrictions = (e) => {
        this.state.isValidField = true
        let invalidEntry = false
        const numericOnly = /^[0-9 +\b]+$/;
        if (e.target.name!=="dob" && e.target.value.indexOf("|") !== -1) {
            return false;
        } else if (e.target.name==="dob") {
          this.state.dob = e.target.value
        }

        switch (e.target.name) {
            case "email":
                e.target.value = (e.target.value).toLowerCase()
                break;
            case "postcode":
                if (e.target.value === ''){
                    invalidEntry = false
                }else if (constants.env.GATSBY_COUNTRY!=="NL" && !numericOnly.test(e.target.value)){
                    invalidEntry = true
                }
                break;
            default:
                break;
        }
        if (!invalidEntry) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    /* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
    render() {
        return (
          <>
            <PrecontractualPopup
              target={this.state.precontractTarget}
              show={this.state.showPrecontract}
              close={() => this.setState({ showPrecontract: false })}
              accept={() => this.setState({ checkbox2: true })}
            />
            {(this.props.isMobile || this.props.isTablet) && (
              <div className="mobile-personal-info-payment">
                <div className="container display-12">
                  <div className="footer-header left-container ">
                    <span><HandleDesc product={planStore.productList} /></span>
                    <span className="display-10">
                      (
                      <Trans
                        values={{
                          count: planStore.productList?.warranty_month === 36 ? 12 : planStore.productList?.warranty_month,
                        }}
                      >
                        product.count-months
                      </Trans>
                      )
                    </span>
                  </div>
                  <div className="right-container">
                    {!deviceStore.voucherCode ? (
                      <>
                        <span className="">
                          {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?<Trans>product.method.monthly</Trans>:<Trans>product.method.oneoff.text</Trans>}
                        </span>
                        <div className="price">
                          <span className="amount">
                            {planStore.productList &&
                            <Trans values={{currency: "€", amount: handleAmount(planStore.productList.totalPremiumDue)}}>
                              product.amount
                            </Trans>
                            }
                          </span>
                          <span className="month">
                            {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?<Trans>product.method.monthly-sym2</Trans>:
                              ( [12, 36].includes(planStore.productList?.warranty_month) ?
                                  <Trans>product.method.yearly-sym</Trans>:
                                  <Trans>product.method.yearly-sym2</Trans>
                              )
                            }
                          </span>
                        </div>
                      </>
                    ) : (
                      <span className="voucher-free">
                        <Trans>detail.free</Trans>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={(this.props.isMobile || this.props.isTablet) ? "mobile-personal-info-form" : "desktop-grid-container"}
            >
              {(this.props.isMobile || this.props.isTablet) && (
                <h2 class="container-responsive mobile-personal-info-subtitle"><Trans>step.s3</Trans></h2>
              )}
              <div
                className={
                  (this.props.isMobile || this.props.isTablet)
                    ? "container display-12"
                    : "display-12 desktop-grid-item"
                }
              >
                <div className="form-group">
                  <label className="form-field-label">
                    <Trans>personal.fullname</Trans>
                  </label>
                  <input
                    ref={this.inputRef.fullName}
                    name="fullName"
                    maxLength="200"
                    value={this.state.fullName}
                    onChange={this.handleInputRestrictions}
                    className={`form-control ${
                      this.state.fullNameError ? "invalid-control" : ""
                    }`}
                    type="text"
                    placeholder={this.props.t('personal.placeholder.fullname')}
                  />
                  <label className="error"><Trans>{this.state.fullNameError}</Trans></label>
                </div>
                <div className="row mb-15">
                  <div className="column-50">
                    <label className="form-field-label"><Trans>personal.dob</Trans></label>
                    <DatePickerComponent
                      ref = {scope => {this.dateObj = scope }}
                      name="dob"
                      value={this.state.dob}
                      onChange={this.handleInputRestrictions}
                      onBlur={this.handleInputRestrictions}
                      focus={this.onFocus.bind(this)}
                      className="form-control"
                      format='dd/MM/yyyy'
                      placeholder={this.props.t('personal.placeholder.dob')}/>
                    {/*<input
                      ref={this.inputRef.dob}
                      name="dob"
                      value={this.state.dob}
                      onChange={this.handleInputRestrictions}
                      className={`form-control ${
                        this.state.dobError ? "invalid-control" : ""
                      }`}
                      type="date"
                      placeholder={this.props.t('personal.placeholder.dob')}
                    />*/}
                    <label className="error"><Trans>{this.state.dobError}</Trans></label>
                  </div>
                  <div className="column-50">
                    <label className="form-field-label"><Trans>personal.mob</Trans></label>
                      {/* <input
                        ref={this.inputRef.mobileNo}
                        name="mobileNo"
                        value={this.state.mobileNo}
                        onChange={this.handleInputRestrictions}
                        className={`pb-13 form-control ${
                          this.state.mobileNoError ? "invalid-control" : ""
                        }`}
                        type="text"
                        placeholder={this.props.t('personal.placeholder.mob')}
                      />
                      <span>
                        <strong>+{constants.env.GATSBY_MOBILE_NO_CODE}</strong>
                      </span> */}
                        <PhoneInput
                          country={this.state.mobileNoCountry}
                          value={this.state.mobileNo}
                          onChange={this.handlePhoneInput}
                          countryCodeEditable={false}
                        />
                    <div className='row mb-15'></div>
                    <label className="error"><Trans>{this.state.mobileNoError}</Trans></label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-field-label"><Trans>personal.email</Trans></label>
                  <input
                    ref={this.inputRef.email}
                    name="email"
                    maxLength="100"
                    value={this.state.email}
                    onChange={this.handleInputRestrictions}
                    className={`form-control ${
                      this.state.emailError ? "invalid-control" : ""
                    }`}
                    type="text"
                    placeholder={this.props.t('personal.placeholder.email')}
                  />
                  <label className="error"><Trans>{this.state.emailError}</Trans></label>
                </div>
                <div className="row">
                  <div className='column-50'>
                    <label className="form-field-label"><Trans>personal.streetName</Trans></label>
                    <input
                      ref={this.inputRef.mailingAddress}
                      name="mailingAddress"
                      value={this.state.mailingAddress}
                      onChange={this.handleInputRestrictions}
                      className={`form-control ${
                        this.state.mailingAddressError ? "invalid-control" : ""
                      }`}
                      type="text"
                      placeholder={this.props.t('personal.placeholder.streetName')}
                    />
                    <label className="error">
                      <Trans>
                      {this.state.mailingAddressError}
                      </Trans>
                    </label>
                  </div>
                  {(constants.env.GATSBY_COUNTRY === 'BE' || constants.env.GATSBY_COUNTRY === 'NL' || constants.env.GATSBY_COUNTRY === 'FI') &&
                    <div className='column-50'>
                    <label className="form-field-label"><Trans>personal.houseNumber</Trans></label>
                    <input
                      ref={this.inputRef.houseNumber}
                      name="houseNumber"
                      value={this.state.houseNumber}
                      onChange={this.handleInputRestrictions}
                      className={`form-control ${
                        this.state.houseNumberError ? "invalid-control" : ""
                      }`}
                      type="text"
                      placeholder={this.props.t('personal.placeholder.houseNumber')}
                    />
                    <label className="error">
                      <Trans>
                      {this.state.houseNumberError}
                      </Trans>
                    </label>
                    </div>
                  }

                </div>
                <div className="row">
                  <div className="column-50">
                    <label className="form-field-label"><Trans>personal.postcode</Trans></label>
                    <input
                      ref={this.inputRef.postcode}
                      name="postcode"
                      maxLength={this.state.postcodeLength}
                      value={this.state.postcode}
                      onChange={this.handleInputRestrictions}
                      className={`form-control ${
                        this.state.postcodeError ? "invalid-control" : ""
                      }`}
                      type="text"
                      placeholder={this.props.t('personal.placeholder.postcode')}
                    />
                    <label className="error"><Trans>{this.state.postcodeError}</Trans></label>
                  </div>
                  <div className="column-50">
                    <label className="form-field-label"><Trans>personal.city</Trans></label>
                    <input
                      ref={this.inputRef.city}
                      name="city"
                      value={this.state.city}
                      onChange={this.handleInputRestrictions}
                      className={`form-control ${
                        this.state.cityError ? "invalid-control" : ""
                      }`}
                      type="text"
                      placeholder={this.props.t('personal.placeholder.city')}
                    />
                    <label className="error"><Trans>{this.state.cityError}</Trans></label>
                  </div>
                </div>
                
                <div className={"pt-20 text-left checkbox-round pb-20"} >
                  <label className="display-12 custom-checkbox-container">
                    <input
                      type="checkbox"
                      name="checkbox2"
                      onChange={this.checkboxChange}
                    />
                    <span className="checkmark" /><Trans>personal.acceptTnc</Trans>
                    {" "}
                    <a onClick={() => this.setState({ showPrecontract: true, precontractTarget: 'tnc' })}>
                      <Trans>personal.tnc</Trans>
                    </a>
                    {", "}
                    <a onClick={() => this.setState({ showPrecontract: true, precontractTarget: 'ipid' })}>
                      <Trans>personal.ipid</Trans>
                    </a>
                    <Trans>personal.and</Trans>
                    <a onClick={() => this.setState({ showPrecontract: true, precontractTarget: 'oii' })}>
                      <Trans>personal.oii</Trans>
                    </a>
                    <Trans
                      components={{
                          1: (
                            (constants.env.GATSBY_COUNTRY === 'FI') ? "30" : "14"
                          ),
                      }}
                    >personal.acceptTnc_2</Trans>
                  </label>
                  <br />
                  {/* <label className="display-12 custom-checkbox-container">
                    <input
                      type="checkbox"
                      name="checkbox1"
                      onChange={this.checkboxChange}
                    />
                    <span className="checkmark" />
                    <Trans>personal.authoriseDebit</Trans>
                  </label>
                  <br /> */}
                  {constants.env.GATSBY_COUNTRY === 'BE' && (
                        <span>
                          <Trans>personal.n1</Trans>
                          <a href="mailto:complaints.be@careplus.co.">complaints.be@careplus.co</a>.
                          <br/><br/>
                          <Trans>personal.n2</Trans>
                          <a href="https://www.ombudsman.as" target="_blank">www.ombudsman.as</a>.
                        </span>
                      )
                    }
                    {constants.env.GATSBY_COUNTRY === 'NL' && (
                          <span>
                            <Trans>personal.n1</Trans>
                            <a href="mailto:complaints.nl@careplus.co">complaints.nl@careplus.co</a>.
                            <br/><br/>
                            {/* <Trans>personal.n2</Trans> */}
                            {/* <a href="https://www.kifid.nl/">https://www.kifid.nl/</a>. */}
                          </span>
                      )
                    }
                    {constants.env.GATSBY_COUNTRY === 'FI' && (
                          <span>
                              <Trans>personal.n1</Trans>
                              <a href="mailto:complaints.fi@careplus.co">complaints.fi@careplus.co</a>.
                              <br/><br/>
                              {/* <Trans>personal.n2</Trans> */}
                          </span>
                      )
                    }
                    <br/><br/><br/>
                  <div className={(this.props.isMobile || this.props.isTablet) ? "" : "text-center"}>
                    <button
                      type="button"
                      onClick={() => this.saveForm()}
                      className={
                        (this.props.isMobile || this.props.isTablet)
                          ? "btn btn-block text-center"
                          : "btn text-center"
                      }
                      disabled={!(this.state.checkbox1 && this.state.checkbox2 && this.state.isValidField && this.validateFieldsEmpty())}
                      id="confirmAndPayBtn"
                    >
                      <Trans>payment.proceed</Trans>
                    </button>
                  </div>
                </div>
              </div>

              {!(this.props.isMobile || this.props.isTablet) && (
                <div className="desktop-bill-wrapper">
                  <div>
                    <div className="footer-header left-container">
                      <span><HandleDesc product={planStore.productList} /></span>
                      <br />
                      <span>
                        (
                        <Trans
                          values={{
                            count: planStore.productList?.warranty_month === 36 ? 12 : planStore.productList?.warranty_month,
                          }}
                        >
                          product.count-months
                        </Trans>
                        )
                      </span>
                    </div>
                    <div className="right-container">
                      {!deviceStore.voucherCode && (
                        <>
                          <div>
                            <span className="">
                              {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?
                                <Trans>product.method.monthly</Trans>:
                                <Trans>product.method.oneoff.text</Trans>
                              }
                            </span>
                          </div>
                          <div className="price">
                            <span className="amount">
                              {planStore.productList &&
                              <Trans values={{currency: "€", amount: handleAmount(planStore.productList.totalPremiumDue)}}>
                                product.amount
                              </Trans>
                              }
                            </span>
                            {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?
                              <span className="month"> <Trans>product.method.monthly-sym2</Trans></span>:
                              ( [12, 36].includes(planStore.productList?.warranty_month) ?
                                  <span className="month"> <Trans>product.method.yearly-sym</Trans></span>:
                                  <span className="month"> <Trans>product.method.yearly-sym2</Trans></span>
                              )
                            }
                          </div>
                        </>
                      )}
                      {deviceStore.voucherCode && (
                        <span className="voucher-free">
                          <Trans>detail.free</Trans>
                        </span>
                      )}
                    </div>

                    {constants.env.GATSBY_COUNTRY === 'BE' && !deviceStore.voucherCode && (
                        <>
                        <hr />
                        <div style={{color: '#4D4D4D'}}>
                          <Trans
                              values={{
                              tax: handleAmount(planStore.productList?.insurancePremiumTax),
                              adminCosts: handleAmount(planStore.productList?.administrativeCost),
                              acquisitionCosts: handleAmount(planStore.productList?.acquisitionCost)}}>
                              product.method.oneoff.content
                          </Trans>
                        </div>
                        </>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* {(this.props.isMobile || this.props.isTablet)  && (
              <div className="footer-fixed personal-info-footer">
                <div className="container-responsive footer-bg">
                {constants.env.GATSBY_COUNTRY === 'BE' && (
                    <div className="text-center padding-top-bottom-8 bold" >
                      <span className="display-10" onClick={this.showModal}>
                        <Trans>others.show-more</Trans>
                      </span>
                      &nbsp;
                      <img src={arrow_up} width="10" height="5" alt="" />
                    </div>
                )}
                  <div className="footer-header left-container ">
                    <span><HandleDesc product={planStore.productList} /></span>
                    <span className="display-10">
                      (
                      <Trans
                        values={{
                          count: planStore.productList?.warranty_month === 36 ? 12 : planStore.productList?.warranty_month,
                        }}
                      >
                        product.count-months
                      </Trans>
                      )
                    </span>
                  </div>
                  <div className="right-container">
                    {!deviceStore.voucherCode ? (
                      <>
                        <span className="">
                          {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?<Trans>product.method.monthly</Trans>:<Trans>product.method.oneoff.text</Trans>}
                        </span>
                        <div className="price">
                          <span className="amount">
                            {planStore.productList &&
                            <Trans values={{currency: "€", amount: handleAmount(planStore.productList.totalPremiumDue)}}>
                              product.amount
                            </Trans>
                            }
                          </span>
                          <span className="month">
                            {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?<Trans>product.method.monthly-sym2</Trans>:
                              ( [12, 36].includes(planStore.productList?.warranty_month) ?
                                  <Trans>product.method.yearly-sym</Trans>:
                                  <Trans>product.method.yearly-sym2</Trans>
                              )
                            }
                          </span>
                        </div>
                      </>
                    ) : (
                      <span className="voucher-free">
                        <Trans>detail.free</Trans>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {(this.props.isMobile || this.props.isTablet) && constants.env.GATSBY_COUNTRY === 'BE' && (
              <div>
                {
                  <ExpandModalComponent
                    onHandleClick={this.showModal}
                    class1={this.state.expandClassName1}
                    class2={this.state.expandClassName2}
                  />
                }
              </div>
            )} */}
            {this.state.token && (<form ref={this.props.formRef} style={{display: 'none'}} method="POST" action={constants.env.PAYMENT_API_URL}  >
              <input name="token" defaultValue={this.state.token} />
              <input name="scopeId" defaultValue={planStore.productList.subscriptionType === "MONTHLY-INSTALLMENT"
                ? constants.env.GATSBY_PAYMENT_SCOPE_ID_MONTHLY
                : constants.env.GATSBY_PAYMENT_SCOPE_ID_ONEOFF} />
              <input name="instance" defaultValue={constants.env.GATSBY_PAYMENT_INSTANCE_ID} />
              <input name="amount" defaultValue={planStore.productList.discountType === constants.DISCOUNT_TYPE.MONTH_PREMIUM_FOC
                ? "100"
                : (planStore.productList.totalPremiumDue * 100).toFixed(0)} />
              <input name="currencyCode" defaultValue={`${constants.env.GATSBY_PAYMENT_CURRENCY_CODE}`} />
              <input name="cardHolderEmail" defaultValue={this.props.customerStoreProps.customerEmail} />
              <input name="phone" defaultValue={this.props.customerStoreProps.customerPhoneNo} />
              <input name="productName" defaultValue={this.props.productDesc} />
              <input name="locale" defaultValue={constants.PAYMENT_LANG_MAPPING[this.props.language]} />
              {/* <input name="productDescription" defaultValue={this.props.productDesc} /> */}
              <input name="customFields1" defaultValue={planStore.orderNo} />
              <input name="customFields2" defaultValue={`${window.location.href.replace('personal-info', '').replace('personal-info/', '')}${constants.env.PAYMENT_REDIRECT_URL}`} />
              {/* <input name="customFields3" defaultValue={planStore.promoDefinition} /> */}
              <input name="callbackFrontendEndpoint" defaultValue={constants.env.PAYMENT_FRONTEND_CALLBACK} />
              <input name="callbackBackendEndpoint" defaultValue={constants.env.PAYMENT_BACKEND_CALLBACK} />
              <input name="paymentAccount" defaultValue={planStore.productList.subscriptionType === "MONTHLY-INSTALLMENT"
                ? constants.env.GATSBY_PAYMENT_ACCOUNT_MONTHLY
                : constants.env.GATSBY_PAYMENT_ACCOUNT_ONEOFF} />
              <input name="recurring" defaultValue={planStore.productList.subscriptionType === "MONTHLY-INSTALLMENT"
                ? 1
                : 0} />
              <input name="paymentMethod" defaultValue={constants.env.GATSBY_PAYMENT_METHOD} />
            </form>)}
          </>
        )
    }

    onFocus(){
        this.dateObj.show();
    }

    checkboxChange = e => {
        this.setState({
            [e.target.name] : e.target.checked
        })
    }

    showModal = () => {
        if(this.state.expandClassName2=== 'slide-in'){
            this.state.expandClassName1 = 'bottom-slide-in-custom-modal-hidden'
            this.state.expandClassName2 = 'slide-out'
        }else{
            this.state.expandClassName1 = 'bottom-slide-in-custom-modal personal-info-footer-slide-in'
            this.state.expandClassName2 = 'slide-in'
        }
        this.setState({
            visible : !this.state.visible
        })
    }

    splitName = () => {
        let fullNameArr = (this.state.fullName).trim().split(' ');
        let firstNameArr = [...fullNameArr];
        firstNameArr.pop();
        return {
            firstName: firstNameArr.reduce((total, item) => total + ` ${item}`),
            lastName: fullNameArr[fullNameArr.length - 1]
        }
    }

    validateFieldsEmpty = () => {
      return !!(this.state.fullName && this.state.mobileNo && this.state.email && this.state.dob && this.state.mailingAddress && this.state.postcode && this.state.city)
    }

    validateFields = () => {
        let isValid = true;
        this.invalidFields = [];
        if (!this.state.fullName || this.state.fullName.trim().split(' ').length === 1) {
            this.setState({
                fullNameError: "personal.error.message.fullName"
            })
            isValid = false;
            this.invalidFields.push(this.inputRef.fullName)
        } else {
            this.setState({ fullNameError: '' })
        }

        if (!this.state.mobileNo || this.state.mobileNo===constants.env.GATSBY_MOBILE_NO_CODE) {
            this.setState({
                mobileNoError: "personal.error.message.mobileNum"
            })
            isValid = false;
            this.invalidFields.push('mobileNo');
        // } else if (this.state.mobileNo.length < 9) {
        } 
        /*else if (!isValidPhoneNumber(this.state.mobileNo)) {
          this.setState({
            mobileNoError: "personal.error.message.mobileNumInvalid"
          })
          isValid = false;
          this.invalidFields.push('mobileNo');
        } */
        else {
            this.setState({ mobileNoError: '' });
        }

        if (!this.state.email) {
            this.setState({
                emailError: "personal.error.message.email"
            })
            isValid = false;
            this.invalidFields.push(this.inputRef.email)
        } else if (!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            this.setState({
                emailError: "personal.error.message.emailValidation"
            })
            isValid = false;
            this.invalidFields.push(this.inputRef.email)
        } else {
            this.setState({ emailError: '' })
        }

        if (!this.state.dob) {
            this.setState({
                dobError: "personal.error.message.dob"
            })
            isValid = false;
            this.invalidFields.push("dob")
        } if (!moment(this.state.dob, 'DD/MM/YYYY', true).isValid()) {
          this.setState({
            dobError: this.props.t('personal.error.message.dobFormatError')
          })
          isValid = false;
          this.invalidFields.push("dob")
        } else {
            let today = new Date()
            let birthDate = moment(this.state.dob, 'DD/MM/YYYY').toDate()
            let age = today.getFullYear() - birthDate.getFullYear()
            let mth = today.getMonth() - birthDate.getMonth()
            if (mth < 0 || (mth === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if(age < 18){
                this.setState({
                    dobError: this.props.t('personal.error.message.dobAgeError')
                })
                isValid = false;
                this.invalidFields.push("dob")
            }else{
                this.setState({ dobError: '' })
            }
        }

        if (!this.state.mailingAddress) {
            this.setState({
                mailingAddressError: "personal.error.message.streetName"
            })
            isValid = false;
            this.invalidFields.push(this.inputRef.mailingAddress)
        } else {
            this.setState({ mailingAddressError: '' })
        }

        if (!this.state.houseNumber && (constants.env.GATSBY_COUNTRY === 'BE' || constants.env.GATSBY_COUNTRY === 'NL' || constants.env.GATSBY_COUNTRY === 'FI')) {
          this.setState({
              houseNumberError: "personal.error.message.houseNumber"
          })
          isValid = false;
          this.invalidFields.push(this.inputRef.houseNumber)
      } else {
          this.setState({ houseNumberError: '' })
      }

        if (!this.state.postcode) {
            this.setState({
                postcodeError: "personal.error.message.postcode"
            })
            isValid = false;
            this.invalidFields.push(this.inputRef.postcode)
        } else if ((constants.env.GATSBY_COUNTRY === 'BE' && (this.state.postcode.length < 4 || this.state.postcode.length > 4)) ||
                    (constants.env.GATSBY_COUNTRY === 'FI' && (this.state.postcode.length < 5 || this.state.postcode.length > 5)) ||
                    (constants.env.GATSBY_COUNTRY === 'NL' && (!this.checkNLValidPostcode(this.state.postcode)))
        ) {
          this.setState({
            postcodeError: "personal.error.message.postcodeInvalid"
          })
          isValid = false;
          this.invalidFields.push(this.inputRef.postcode)
        } else {
            this.setState({ postcodeError: '' })
        }

        if (!this.state.city) {
            this.setState({
                cityError: "personal.error.message.city"
            })
            isValid = false;
            this.invalidFields.push(this.inputRef.city)
        } else {
            this.setState({ cityError: '' })
        }

        this.invalidFields.length && this.scrollToFirstInvalidInput()
        this.state.isValidField = isValid
        return isValid
    }

    checkNLValidPostcode = (value) => {
        let first4 = value.substring(0,4)
        let last2 = value.slice(-2)
        if(value.length !== 6 || !(first4 && first4.length === 4 && /^\d+$/.test(first4)) || !(last2 && /^[A-Z]+$/.test(last2))){
            return false
        }
        return true
    }

    scrollToFirstInvalidInput = () => {
        if(this.invalidFields[0] === 'dob'){
            document.documentElement.scrollTop = 0;
        } else if(this.invalidFields[0] === 'mobileNo'){
            document.documentElement.scrollTop = 0;
        }else{
          this.invalidFields[0].current.focus();
        }
    }

    saveForm = () => {
        if (this.validateFields()) {
            let fullNameObj = this.splitName();
            let customerPhoneNo = this.state.mobileNo;
            if(customerPhoneNo.startsWith("+")) {
              customerPhoneNo = customerPhoneNo.substring(1);
            }
            let customerPhoneNoCountryCode = constants.env.GATSBY_MOBILE_NO_CODE;
            if(this.props.customerStoreProps.customerId!= null){
                customerPhoneNo = customerPhoneNo.replace(customerPhoneNoCountryCode,'');
            }
            const dateFormat = moment(this.state.dob, 'DD/MM/YYYY', true).isValid()? 'DD/MM/YYYY':'YYYY/MM/DD';
            let customerObj = {
              isValidatedForm: true,
              customerFirstName: fullNameObj.firstName,
              customerLastName: fullNameObj.lastName,
              customerEmail: this.state.email,
              customerPhoneNo: customerPhoneNo,
              customerPhoneNoCountryCode: customerPhoneNoCountryCode,
              customerDateOfBirth: moment(this.state.dob, dateFormat).format('YYYY-MM-DD').toString(),
              customerMailingAddress: this.state.mailingAddress,
              customerHouseNumber: this.state.houseNumber,
              customerPostcode: this.state.postcode,
              customerCity: this.state.city,
              userConsent: String(this.state.checkbox1),
              userAgeConsent: String(this.state.checkbox2),
              customerId: this.props.customerStoreProps.customerId
            }
            this.props.customerStoreProps.updateCustomerDetail(customerObj);
            this.startPay();
        }
    }

    doPay = async () => {
        const nowTime = new Date().getTime();
        if(nowTime - Number(localStorage.getItem("startTime")) > constants.SESSION_TIMEOUT.TIMEOUT) {
          localStorage.clear()
          this.props.navigate(constants.ROUTES.HOME)
        } else {
          this.props.navigate(constants.ROUTES.PAYMENT)
        }
    }

    saveNonValidatedDetailsToStore = () => {
        let customerObj = {
            isValidatedForm: false,
            customerFirstName: this.state.fullName,
            customerLastName: '',
            customerPhoneNo: this.state.mobileNo,
            customerEmail: this.state.email,
            customerDateOfBirth: moment(this.state.dob, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
            customerMailingAddress: this.state.mailingAddress,
            customerPostcode: this.state.postcode,
            customerCity: this.state.city,
        }
        this.props.customerStoreProps.updateCustomerDetail(customerObj);
    }

    startPay = () => {
      loaderStore.showLoader(true)
      this.preparePayment()
    }
  
    preparePayment = async() => {
      const accessToken = await getOrderToken(true);
      if (accessToken === null) {
        // TODO: not available order token, retrigger again, but this would cause overflow if api failed
        this.preparePayment();
        return
      }
      let productPrice = (planStore.productList.totalPremiumDue).toFixed(2)
      if (deviceStore.voucherCode) {
        productPrice = "0.00"
      } else if (planStore.productList?.promotionDiscount && planStore.productList?.discountType === constants.DISCOUNT_TYPE.MONTH_PREMIUM_FOC) {
        productPrice = "1.00"
      }
      const params = {
        store_id: "bolttech D2C",
        device_unique_id: deviceStore.deviceImei,
        plan_id: planStore.planId,
        product_id: planStore.productId,
        device_activation_date: deviceStore.deviceActivationDate ? deviceStore.deviceActivationDate : new Date(Date.now()).toISOString(),
        payment_amount: productPrice,
        bill_address_line1: this.props.customerStoreProps.customerHouseNumber ? this.props.customerStoreProps.customerHouseNumber + ', ' + this.props.customerStoreProps.customerMailingAddress : this.props.customerStoreProps.customerMailingAddress,
        bill_address_city: this.props.customerStoreProps.customerCity,
        bill_address_postal_code: this.props.customerStoreProps.customerPostcode,
        customer_email: this.props.customerStoreProps.customerEmail,
        customer_first_name: this.props.customerStoreProps.customerFirstName,
        customer_last_name: this.props.customerStoreProps.customerLastName,
        customer_mobile_no: this.props.customerStoreProps.customerPhoneNo,
        customer_mobile_no_country_code: this.props.customerStoreProps.customerPhoneNoCountryCode,
        customer_consent: true,
        customer_date_of_birth: this.props.customerStoreProps.customerDateOfBirth,
        product_price: productPrice,
        device_model: deviceStore.deviceModel,
        partner_code: constants.partnerCode,
        device_id_type: PlanMapping[deviceStore.deviceType],
        subscription_type: planStore.productList.subscriptionType,
        update_status_url: constants.env.UPDATE_STATUS_URL,
        custIdNumber: this.props.customerStoreProps.customerId,
        cid: deviceStore.cid,
      }
      if (deviceStore.voucherCode) {
        params.orderStatus = "fulfillment-pending"
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": constants.HEADER_REQUEST.contentType,
          "x-api-key": constants.env.ORDER_X_API_KEY,
          "bolt-country-code": constants.env.GATSBY_COUNTRY,
          "bolt-tenant-id": constants.env.GATSBY_TENANT_ID,
          "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode,
          "bolt-channel": constants.HEADER_REQUEST.boltChannel
        },
        body: JSON.stringify(params)
      };
      try {
        // sample response
        // {
        //   order_id: "307554368828",
        //   created_at: "2021-11-01T06:33:36.006Z",
        //   order_status: "payment-pending",
        //   customer_id: "CUST02569762",
        //   partner_order_id: null,
        //   _status: { code: "80001", message: "Success" },
        // }
        const response = await (await fetch(constants.env.ORDER_API_URL, requestOptions)).json()
  
        if (
          response._status.message.toLowerCase() === "success" &&
          response._status.code === "80001"
        ) {
          planStore.updateOrderNo(response.order_id)
          planStore.updateOrderStatus(response.order_status)
          localStorage.setItem(
            `orderDetail_${response.order_id}`,
            JSON.stringify(planStore)
          )
          constants.writeStorageLang(this.props.language)
  
          // foc voucher no need payment
          if (deviceStore.voucherCode) {
            // create crmz policy
            this.createPolicy(params, response)
            this.props.navigate(
              `/handlePaymentResponse?${queryString.stringify({
                status: "success",
                orderNumber: response.order_id,
              })}`
            )
            return
          }
          // only for local development
          if (process.env.NODE_ENV  === 'development') {
            let status
            if (
              window.confirm(
                `Press 'OK' for success payment, 'Cancel' for failed payment.`
              )
            ) {
              status = "success"
            } else {
              status = "fail"
            }
            this.props.navigate(
              `/handlePaymentResponse?${queryString.stringify({
                status,
                orderNumber: response.order_id,
              })}`
            )
            // skip below
            return
          }

          const res = await (await fetch(constants.env.PAYMENT_TOKEN_API_URL, {
            method: "post",
            headers: {
              "Content-Type": constants.HEADER_REQUEST.contentType,
            },
            body: JSON.stringify({
              client_id: constants.env.GATSBY_PAYMENT_CLIENT_ID,
              client_secret: constants.env.GATSBY_PAYMENT_CLIENT_SECRET,
              grant_type: constants.env.GATSBY_PAYMENT_GRANT_TYPE,
              scope: constants.env.GATSBY_PAYMENT_SCOPE,
            }),
          })).json()

          // as long as has access token, this should be able to submit payment
          if (res.access_token) {
            // this would trigger form submit as there is available of token
            this.setState({ token: res.access_token })
            this.props.formRef.current.submit();
          } else {
            throw new Error('Invalid payment token fetched')
          }
          loaderStore.showLoader(false)
        } else {
          throw new Error('Invalid order api returned')
        }
      } catch (error) {
        // any error thrown inside will go to failed payment, if wan debug, put debugger on here
        loaderStore.showLoader(false)
        this.props.navigate(constants.ROUTES.PAYMENT_FAIL)
      }
    }

    createPolicy = (param, order) => {
      const crmzPolicyReq = {
        countryCode: constants.env.GATSBY_COUNTRY,
        creationTime: moment.utc().format("HH:mm:ss"),
        coverage: null,
        custAddress: {
          address1: param.bill_address_line1,
          address2: null,
          city: param.bill_address_city,
          countryCode: constants.env.GATSBY_COUNTRY_CODE,
          postalCode: param.bill_address_postal_code,
          state: null
        },
        custMobileNumber: param.customer_mobile_no,
        customerEmail: param.customer_email,
        customerLastName: param.customer_last_name,
        customerName: param.customer_first_name,
        customerDateOfBirth: param.customer_date_of_birth,
        deviceMake: param.device_make,
        deviceModel: param.device_model,
        deviceType: param.device_type || "",
        orderId: order.order_id,
        paymentAmount: param.product_price,
        policyStartDate: moment.utc().format("DD/MM/yyyy"),
        policyPurchaseDate: moment.utc().format("DD/MM/yyyy"),
        deviceDiagnosticUrl: null,
        deviceRRP: null,
        partnerProductId: planStore.planId,
        productCode: param.product_id,
        deviceActivationDate: param.device_activation_date,
        partnerId: param.partner_code,
        productName: null,
        deviceSoldDate: moment.utc().format("DD/MM/yyyy"),
        storeCode: param.store_id,
        storeName: null,
        customerId: param.customerId,
        brokerOrderId: param.partnerOrderId,
        salesRepId: param.salesRepId,
        subscriptionFlag: true,
        sku: null,
        customerDeviceDiagnosticConsent: false,
        partnerContractId: null,
        salesChannelId: null,
        retailerName: null,
        paymentId: null,
        subscriptionType: param.subscription_type,
        policyPurchaseDateWithTime: moment.utc().format("YYYYMMDDHHmmss"),
        policyStartDateWithTime: moment.utc().format("YYYYMMDDHHmmss"),
        paymentType: null,
        valueAddedServices: null,
        voucherCode: deviceStore.voucherCode,
      }
      if (param.device_id_type === "imei") {
        crmzPolicyReq.policyIMEINumber = param.device_unique_id;
      } else {
        crmzPolicyReq.deviceSerialNo = param.device_unique_id;
      }
  
      const policyRequest = {
        method: 'POST',
        headers: {
          "Content-Type": constants.HEADER_REQUEST.contentType,
          "x-api-key": constants.env.GET_PRODUCT_LIST_X_API_KEY,
        },
        body: JSON.stringify(crmzPolicyReq)
      };
      // trigger create policy API
      fetch(constants.env.GET_POLICY_CREATE_URL + encodeURIComponent("Samsung Bolttech D2C"), policyRequest).then(res => res.json())
        .then(response => {
          console.log('create policy response', response.status)
        })
        .catch(err => {
          console.log("Voucher Create Policy Exception", err)
        })
    }
}

export default withTranslation()(PersonalInfo);
